// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("tshirt.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-art {
  background-color: #000;
  color: #fff;
  padding: 20px;
}

.description-input {
  width: 100%;
  height: 100px;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 16px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  outline: none;
}

.tshirt-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
  position: relative;
}

.tshirt {
  width: 400px;
  height: 500px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center center;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.tshirt-text {
  color: #000;
  font-size: 18px;
  text-align: center;
  z-index: 1;
  max-width: 90%;
  word-wrap: break-word;
}
`, "",{"version":3,"sources":["webpack://./src/components/CreateArt.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,WAAW;EACX,aAAa;AACf;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,eAAe;EACf,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,2EAAqD;EACrD,wBAAwB;EACxB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,UAAU;EACV,cAAc;EACd,qBAAqB;AACvB","sourcesContent":[".create-art {\n  background-color: #000;\n  color: #fff;\n  padding: 20px;\n}\n\n.description-input {\n  width: 100%;\n  height: 100px;\n  margin-bottom: 20px;\n  padding: 10px;\n  font-size: 16px;\n  background-color: #333;\n  color: #fff;\n  border: none;\n  border-radius: 5px;\n  outline: none;\n}\n\n.tshirt-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 600px;\n  position: relative;\n}\n\n.tshirt {\n  width: 400px;\n  height: 500px;\n  background: url(\"tshirt.svg\") no-repeat center center;\n  background-size: contain;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: relative;\n  overflow: hidden;\n}\n\n.tshirt-text {\n  color: #000;\n  font-size: 18px;\n  text-align: center;\n  z-index: 1;\n  max-width: 90%;\n  word-wrap: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
