import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faPaperPlane,
  faPen,
  faCirclePlus
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from 'react-router-dom';
import './index.css';
import { getThemeById } from '../../../api/theme';
import { reflect, paint, chat } from '../../../api/ai';
import { DotLoading } from 'antd-mobile'; // Import DotLoading for loading animation

// CreateFromTheme component
function CreateFromTheme() {
  const { id } = useParams();
  const navigate = useNavigate();


  // State for input value
  const [inputValue, setInputValue] = useState('');
  // State for prompt
  const [prompt, setPrompt] = useState('');
  // State for completion
  const [completion, setCompletion] = useState([]);

  // State for image preview
  const [imagePreview, setImagePreview] = useState(null);

  // State for messages
  const [messages, setMessages] = useState([

  ]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Function to get theme by ID
  useEffect(() => {
    if (id != "upload") {
      getThemeById(id).then(res => {
        setImagePreview(res.theme.previewImage);
        setPrompt(res.theme.positivePrompt);
        setMessages([
          { text: "My MidJourney prompt:" + res.theme.positivePrompt + ".Can you help me improve it?", from: "right" },
          { text: "Sure! What colour of T-shirt do you want to create?", from: "left" }
        ])

        setCompletion([
          {
            "role": "user",
            "content": "My MidJourney prompt:" + res.theme.positivePrompt + ".Can you help me improve it?"
          },
          {
            "role": "assistant",
            "content": "Sure! What colour of T-shirt do you want to create?"
          },

        ])
      });
    }
  }, [id]);


  // 滚动到底部
  const scrollToBottom = () => {
    const chatBody = document.querySelector('.chat-area'); // 获取聊天区域
    chatBody.scrollTop = chatBody.scrollHeight; // 滚动到底部
  };
  // Function to handle file change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        const base64Image = reader.result.split(',')[1];
        reflect({ image_base64: base64Image }).then(response => {
          setPrompt(response.data.prompt);
          setMessages([
            { text: "My prompt is:" + response.data.prompt + ".Can you help me improve it?", from: "right" },
            { text: "Sure! What colour of T-shirt do you want to create?", from: "left" }
          ])

        })
      };
      reader.readAsDataURL(file);
    }
  };

  // Function to handle send message
  const handleSend = () => {
    if (inputValue.trim()) {
      const userMessage = { text: inputValue, from: "right" };
      setMessages([...messages, userMessage]); // Add user message to chat
      setInputValue(''); // Clear input after sending
      const system_prompt = `You are a customer service robot. **At every end of message, you MUST output an MidJourney prompt starting with "/imaginStart" ending with "/imaginEnd" according to whole previous conversations**. Please ask the customer these questions to understand their T-shirt design needs:
What colour of T-shirt do you want to create?
Describe the image you want to generate.
Ask for more details about the image (e.g., style, elements, mood, specific actions/poses).
Don't exceed 7 questions.
start the conversation with the first question exactly, you can list a few answers for the question you just asked to remind the customer.
never mention "T-shirt" in the prompt, mention a design on (colour) background, and a transparent background for the graphic so that it blends well with the background
 Include the T-shirt colour and add "no T-shirt" to ensure the T-shirt is not generated" While asking customer questions, please ask one at a time, wait for the response then ask the next one, also before the final prompt output, please correct the customer's input spelling mistake and try to interpret the intention of the customer, expand the abbreviation, or some words.`;
      // Call the chat API
      setMessages(prevMessages => [...prevMessages, { text: <DotLoading />, from: "left" }]); // Add loading message to chat
      chat({ prompt: inputValue, messages: completion, system_prompt }).then(response => {
        const startIndex = response.data.response.indexOf("/imaginStart") + "/imaginStart".length;
        const endIndex = response.data.response.indexOf("/imaginEnd");
        const messageText = response.data.response.substring(0, startIndex).trim().replace("/imaginStart", "").replace("/imaginEnd", "");
        const promptText = response.data.response.substring(startIndex, endIndex).trim();
        const aiMessage = { text: messageText, from: "left" };
        setMessages(prevMessages => [...prevMessages.slice(0, -1), aiMessage]);
        console.log(promptText);
        setPrompt(promptText); // Set the prompt
        setCompletion(response.data.completion);
      }).catch(error => {
        const errMessage = { text: "Failed to generate. Please try again.", from: "left" };
        setMessages(prevMessages => [...prevMessages.slice(0, -1), errMessage]);
      });;
    }
  };
  // Function to handle key down event
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevent default behavior (new line)
      handleSend(); // Call send function
    }
  };


  const handleGenerate = () => {
    navigate(`/create-result/${prompt}`);
  }


  // Render the component
  return (
    <div className="create-from-theme">
      <div className="head-area">
        <div className="title" onClick={() => navigate(`/creation-center`)}><FontAwesomeIcon icon={faArrowLeft} /> Create By Themes</div>
        <div className={`button ${prompt ? 'active' : ''}`} onClick={prompt ? handleGenerate : null}>
          Generate
        </div>
      </div>
      <div className="picture-area">
        <div className="upload-box">
          <input type="file" id="upload" style={{ display: 'none' }} onChange={handleFileChange} />
          <label htmlFor="upload">
            {!imagePreview && <FontAwesomeIcon icon={faCirclePlus} className='icon' />}
            {!imagePreview && <div>Upload</div>}
            {imagePreview && (
              <img src={imagePreview} alt="Preview" className="image-preview" />
            )}
          </label>
        </div>
        <div className="prompt">
          <textarea
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder="Enter your prompt here..."
          />
        </div>
      </div>
      <div className="chat-area">
        {messages.map((msg, index) => (
          <div className={`chat-bubble ${msg.from}`} key={index}>
            {msg.from === "left" && <img src="../assets/images/gpt-icon.png" className="avatar" />}
            <div className="message">{msg.text}</div>
            {msg.from === "right" && <img src="https://placehold.co/24x24" className="avatar" />}
          </div>
        ))}
      </div>
      <div className="input-area">
        <div className="input">
          <FontAwesomeIcon icon={faPen} className='icon' />
          <textarea
            placeholder="Say something..."
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
            rows={3} // Set initial rows for the textarea
          />
        </div>
        <div className="button" onClick={handleSend}><FontAwesomeIcon icon={faPaperPlane} /></div>
      </div>
    </div>
  );
}

// Export the component
export default CreateFromTheme;