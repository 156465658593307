import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRotateLeft,
  faArrowDown,
  faCirclePlus
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from 'react-router-dom';
import { Toast, Input, Image } from 'antd-mobile';
import { paint } from '../../../api/ai';

import './index.css';
// CreateResult component
function CreateResult() {
  const navigate = useNavigate();
  const prompt = useParams().prompt;

  const [selectedPicture, setSelectedPicture] = useState([]);
  const [images, setImages] = useState([]);

  const [generated, setGenerated] = useState(false);

  useEffect(() => {

    handlePaint();
  }, []);

  const toggleCheckbox = (id) => {
    setSelectedPicture([id]);
  };

  const handleContinue = () => {
    if (selectedPicture.length > 0) {
      const image = images.find(image => image.id === selectedPicture[0]);
      navigate(`/create-result-selected/${btoa(image.src)}`);
    } else {
      Toast.show({
        icon: 'fail',
        content: 'Please select one picture.',
      });
    }
  };

  const handleDownLoad = () => {
    if (selectedPicture.length > 0) {
      const image = images.find(image => image.id === selectedPicture[0]);
      if (image && image.src) {
        const link = document.createElement('a');
        link.href = image.src;
        link.setAttribute('download', 'download.png'); // 确保设置 download 属性
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        Toast.show({
          icon: 'fail',
          content: 'Failed to download the image.',
        });
      }
    } else {
      Toast.show({
        icon: 'fail',
        content: 'Please select one picture.',
      });
    }
  }

  const handlePaint = () => {
    setGenerated(false);
    setImages([{ id: 1, src: "../../assets/images/loading.gif", width: 50, height: 50 },
    { id: 2, src: "../../assets/images/loading.gif", width: 50, height: 50 },
    { id: 3, src: "../../assets/images/loading.gif", width: 50, height: 50 },
    { id: 4, src: "../../assets/images/loading.gif", width: 50, height: 50 }])
    paint({ prompt: prompt, negative_prompt: "violance, bad" }).then(res => {
      setGenerated(true);
      setImages(res.data.images.map(image => ({ id: image.image_id, src: image.image_url, width: "100%", height: "100%" })));
    });
  }


  // Render the component
  return (
    <div className="create-result">
      <div className="head-area">
        <div className="title" onClick={() => navigate(`/creation-center`)}><FontAwesomeIcon icon={faArrowLeft} />Select a painting</div>
      </div>
      <div className="picture-area">
        {images.map(picture => (
          <div className="picture" key={picture.id} onClick={() => toggleCheckbox(picture.id)}>
            <img src={picture.src} style={{ width: picture.width, height: picture.height }} />
            <div
              className={`checkbox ${selectedPicture.includes(picture.id) ? 'checked' : 'unchecked'}`}
            >
            </div>
          </div>
        ))}

      </div>
      <div className={`regenerate-button ${generated ? 'active' : ''}`} onClick={generated ? handlePaint : null}><FontAwesomeIcon icon={faArrowRotateLeft} /> Regenerate all</div>

      <div className="input-area">

        <div className={`down-button ${selectedPicture.length > 0 && generated ? 'active' : ''}`} onClick={selectedPicture.length > 0 && generated ? handleDownLoad : null}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M7 10L12 15L17 10" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M12 15V3" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        </div>
        <div className={`exe-button ${selectedPicture.length > 0 && generated ? 'active' : ''}`} onClick={selectedPicture.length > 0 && generated ? handleContinue : null}>
          Select and Continue
        </div>
      </div>
    </div>
  );
}

// Export the component
export default CreateResult;