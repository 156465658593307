import { Toast, Input, Image } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import './index.css';
import { login } from '../../api/user';

export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState(''); // 添加状态
  const [password, setPassword] = useState(''); // 添加状态
  const loginHandler = () => {
    login({ email, password }).then((response) => {
      Toast.show({
        icon: 'success',
        content: 'Login successful!',
      });
      localStorage.setItem('token', response.token);
      localStorage.setItem('userInfo', JSON.stringify(response.user));
      navigate('/creation-center');
    });
  }

  const handleGoogleLogin = () => {
    window.location.href = 'api/users/auth/google'; // 重定向到 Google 登录
  }
  const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const isFormValid = isEmailValid.test(email) && password;

  return (
    <div className='login-page'>
      <div className="container">
        <div className="logo">
          <Image src='../assets/images/logo-color.png' width={220} height={58} fit='fill'></Image>
        </div>
        <div className="input-group">
          <Input
            placeholder='Email'
            value={email} // 绑定状态
            onChange={(e) => setEmail(e)} // 更新状态
          />
        </div>
        <div className="input-group">
          <Input
            type="password"
            placeholder='Password'
            value={password} // 绑定状态
            onChange={(e) => setPassword(e)} // 更新状态
          />
        </div>
        <button className="login-button" onClick={loginHandler} disabled={!isFormValid}>login</button>
        <div className="divider">
          <span>OR login with</span>
        </div>
        <div className="social-login">
          <img src="https://placehold.co/40x40?text=IG" alt="Instagram" />
          <img src="https://placehold.co/40x40?text=FB" alt="Facebook" />
          <img src="https://placehold.co/40x40?text=G" alt="Google" onClick={handleGoogleLogin} style={{ cursor: 'pointer' }} />
        </div>
        <div className="signup">
          Don't have an account? <a onClick={() => navigate('/register')}>Sign up.</a>
        </div>
      </div>
    </div>
  );
}