import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRotateLeft,
  faArrowDown,
  faCirclePlus,
  faRedo
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from 'react-router-dom';
import { Toast, Input, Image } from 'antd-mobile';
import { paint } from '../../../api/ai';

import './index.css';
// CreateSelected component
function CreateSelected() {
  const navigate = useNavigate();
  const imageUrl = atob(useParams().link);
  const [picture, setPicture] = useState(imageUrl);

  const [generated, setGenerated] = useState(true);

  useEffect(() => {

  }, []);

  const handleZoomIn = () => {

  }

  const handleZoomOut = () => {

  }



  const handleContinue = () => {
    
  };

  const handleDownLoad = () => {
    const link = document.createElement('a');
    link.href = picture;
    link.setAttribute('download', 'download.png'); // 确保设置 download 属性
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const handlePaint = () => {

  }


  // Render the component
  return (
    <div className="create-selected">
      <div className="head-area">
        <div className="title" onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowLeft} /> Painting Selected</div>
      </div>
      <div className="picture-area">
        <div className="picture" >
          <img src={picture} />
        </div>
      </div>
      <div className="buttons">
        <div className={`regenerate-button ${generated ? 'active' : ''}`} onClick={handleZoomOut}><FontAwesomeIcon icon={faRedo} /> Zoom Out 2X</div>
        <div className={`regenerate-button ${generated ? 'active' : ''}`} onClick={handleZoomIn}><FontAwesomeIcon icon={faRedo} /> Zoom In 2X</div>
        <div className={`regenerate-button ${generated ? 'active' : ''}`} onClick={handlePaint}><FontAwesomeIcon icon={faRedo} /> Vary Region</div>
      </div>

      <div className="input-area">
        <div className={`down-button ${generated ? 'active' : ''}`} onClick={generated ? handleDownLoad : null}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M7 10L12 15L17 10" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M12 15V3" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        </div>
        <div className={`exe-button ${generated ? 'active' : ''}`} onClick={generated ? handleContinue : null}>
          Go to Product Maker
        </div>
      </div>
    </div>
  );
}

// Export the component
export default CreateSelected;