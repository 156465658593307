import service from '../utils/request';

// Google login
export const googleLogin = () => {
  return service.get('/users/auth/google');
};

// Google callback
export const googleCallback = (token) => {
  return service.post('/users/auth/google/callback', { token });
};

// User registration
export const register = (userData) => {
  return service.post('/users/register', userData);
};

// User login
export const login = (credentials) => {
  return service.post('/users/login', credentials);
};

// Get user info
export const getUserInfo = (userId) => {
  return service.get(`/users/${userId}`);
};

// Update user info
export const updateUserInfo = (userId, updates) => {
  return service.put(`/users/${userId}`, updates);
};

// Delete user account
export const deleteUserAccount = (userId) => {
  return service.delete(`/users/${userId}`);
};

// Third-party login (placeholder)
export const thirdPartyLogin = () => {
  // Implement third-party login logic here
};