import service from '../utils/request';

// Health Check
export const healthCheck = () => {
  return service.get('/ai/health');
};

// Create Art
export const createArt = (artData) => {
  return service.post('/ai/create_art', artData);
};

// Chat with AI
export const chat = (chatData) => {
  return service.post('/ai/chat', chatData);
};

// Reflect Image
export const reflect = (imageData) => {
  return service.post('/ai/reflect', imageData);
};

// Paint Image
export const paint = (paintData) => {
  return service.post('/ai/paint', paintData);
};