import service from '../utils/request';

// Create a new theme
export const createTheme = (themeData) => {
  return service.post('/themes', themeData);
};

// Retrieve all themes
export const getAllThemes = () => {
  return service.get('/themes');
};

// Retrieve a single theme by ID
export const getThemeById = (themeId) => {
  return service.get(`/themes/${themeId}`);
};

// Update a theme by ID
export const updateTheme = (themeId, updates) => {
  return service.put(`/themes/${themeId}`, updates);
};

// Delete a theme by ID
export const deleteTheme = (themeId) => {
  return service.delete(`/themes/${themeId}`);
};