// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bottom-nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 60px;
  background-color: #000;
  border-top: 1px solid #333;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.bottom-nav a {
  color: #fff;
  text-decoration: none;
  font-size: 24px;
}

.bottom-nav .active {
  color: rgba(198, 34, 207, 1);
}

.bottom-nav .create {
  color: #fff;
  background: linear-gradient(45deg, rgba(246, 46, 142, 1), rgba(172, 26, 240, 1));
  border-radius: 50%;
  padding: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.bottom-nav .create-inactive {
  color: #fff;
  background: linear-gradient(45deg, rgba(246, 46, 142, 1), rgba(172, 26, 240, 1));
  border-radius: 50%;
  padding: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;


}`, "",{"version":3,"sources":["webpack://./src/components/BottomNav.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,YAAY;EACZ,sBAAsB;EACtB,0BAA0B;EAC1B,eAAe;EACf,SAAS;EACT,WAAW;AACb;;AAEA;EACE,WAAW;EACX,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,WAAW;EACX,gFAAgF;EAChF,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,gFAAgF;EAChF,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;;;AAGlB","sourcesContent":[".bottom-nav {\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  height: 60px;\n  background-color: #000;\n  border-top: 1px solid #333;\n  position: fixed;\n  bottom: 0;\n  width: 100%;\n}\n\n.bottom-nav a {\n  color: #fff;\n  text-decoration: none;\n  font-size: 24px;\n}\n\n.bottom-nav .active {\n  color: rgba(198, 34, 207, 1);\n}\n\n.bottom-nav .create {\n  color: #fff;\n  background: linear-gradient(45deg, rgba(246, 46, 142, 1), rgba(172, 26, 240, 1));\n  border-radius: 50%;\n  padding: 10px;\n  width: 40px;\n  height: 40px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-weight: 600;\n}\n\n.bottom-nav .create-inactive {\n  color: #fff;\n  background: linear-gradient(45deg, rgba(246, 46, 142, 1), rgba(172, 26, 240, 1));\n  border-radius: 50%;\n  padding: 10px;\n  width: 40px;\n  height: 40px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-weight: 600;\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
