import React, { useState } from 'react';
import './CreateArt.css';

const CreateArt: React.FC = () => {
  const [description, setDescription] = useState('');

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
  };

  return (
    <div className="create-art">
      <h1>Create Art</h1>
      <textarea
        className="description-input"
        placeholder="Enter your design description here..."
        value={description}
        onChange={handleDescriptionChange}
      />
      <div className="tshirt-container">
        <div className="tshirt">
          <p className="tshirt-text">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default CreateArt;
