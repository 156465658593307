import { Toast, Input, Image } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import './index.css';
import { register } from '../../api/user';

export default function Register() {
  const navigate = useNavigate();
  const [email, setEmail] = useState(''); // 添加状态
  const [password, setPassword] = useState(''); // 添加状态
  const [confirmPassword, setConfirmPassword] = useState(''); // 添加状态

  const registerHandler = () => {
    if (isFormValid) {
      register({ email, password }) // Call the register API
        .then(() => {
          Toast.show({
            icon: 'success',
            content: 'Registration successful!',
          })
          navigate('/login');
        })
        .catch((error) => {
          Toast.show({
            icon: 'fail',
            content: 'Registration failed:' + error,
          })
        });
    }
  }

  const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const isFormValid = isEmailValid.test(email) && password && (password === confirmPassword);

  return (
    <div className='register-page'>
      <div className="container">
        <div className="logo">
          <Image src='../assets/images/logo-color.png' width={220} height={58} fit='fill'></Image>
        </div>

        <div className="input-group">
          <Input
            placeholder='Email'
            onChange={(e) => setEmail(e)} // 更新状态
          />
        </div>
        <div className="input-group">
          <Input
            type="password"
            placeholder='Password'
            onChange={(e) => setPassword(e)} // 更新状态
          />
        </div>
        <div className="input-group">
          <Input
            type="password"
            placeholder='Confirm Password'
            onChange={(e) => setConfirmPassword(e)} // 更新状态
          />
        </div>
        <button className="register-button" onClick={registerHandler} disabled={!isFormValid}>Sign Up</button> 
        <div className="signup">
          Already have an account? <a onClick={() => navigate('/login')}>Login</a>
        </div>

      </div>
    </div>
  );
}