import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faSearch,
  faShoppingCart,
  faUser,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./BottomNav.css";

const BottomNav: React.FC = () => {
  return (
    <nav className="bottom-nav">
      <NavLink
        to="/"
        end
        className={({ isActive }) => (isActive ? "active" : "")}
      >
        <FontAwesomeIcon icon={faHome} />
      </NavLink>
      <NavLink
        to="/search"
        className={({ isActive }) => (isActive ? "active" : "")}
      >
        <FontAwesomeIcon icon={faSearch} />
      </NavLink>
      <NavLink
        to="/creation-center"
        className={({ isActive }) => (isActive ? "create" : "create-inactive")}
      >
        <FontAwesomeIcon icon={faPlus} />
      </NavLink>
      <NavLink
        to="/cart"
        className={({ isActive }) => (isActive ? "active" : "")}
      >
        <FontAwesomeIcon icon={faShoppingCart} />
      </NavLink>
      <NavLink
        to="/profile"
        className={({ isActive }) => (isActive ? "active" : "")}
      >
        <FontAwesomeIcon icon={faUser} />
      </NavLink>
    </nav>
  );
};

export default BottomNav;
