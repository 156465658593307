import axios from "axios";
import { Toast } from 'antd-mobile';

const service = axios.create({
  timeout: 100000,
  baseURL: process.env.REACT_APP_KRG_SERVICE_API_BASE_URL,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
  }
});

service.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token && config.url !== '/register' && config.url !== '/login') {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

service.interceptors.response.use((response) => {
  if (response.data.code === 200) {
    return Promise.resolve(response.data);
  } else {
    const errorMessages = {
      400: 'Bad Request',
      401: 'Unauthorized',
      500: 'Internal Server Error',
    };

    const errorMessage = errorMessages[response.data.code] || 'Error';

    Toast.show({
      icon: 'fail',
      content: response.data.message,
    });

    return Promise.reject(new Error(`${errorMessage}: ${response.data.message}`));
  }
}, (error) => {
  return Promise.reject();
});

export default service;