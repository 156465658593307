import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faPaperPlane,
  faPen
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
import { chat } from '../../../api/ai';
import { DotLoading } from 'antd-mobile'

import './index.css'; // Import the related CSS file

// Function component for CreateFromDescription
function CreateFromDescription() {
  const navigate = useNavigate();

  // State for messages
  const [messages, setMessages] = useState([
    { text: "I am your T-shirt design assistant. What colour of T-shirt do you want to create?", from: "left" }
  ]);
  const [completion, setCompletion] = useState([
    {
      "role": "assistant",
      "content": "I am your T-shirt design assistant. What colour of T-shirt do you want to create?"
    },
  ]);
  // State for input value
  const [inputValue, setInputValue] = useState('');
  const [prompt, setPrompt] = useState('');

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // 滚动到底部
  const scrollToBottom = () => {
    const chatBody = document.querySelector('.chat-area'); // 获取聊天区域
    chatBody.scrollTop = chatBody.scrollHeight; // 滚动到底部
  };
  // Function to handle send message
  const handleSend = () => {
    if (inputValue.trim()) {
      const userMessage = { text: inputValue, from: "right" };
      setMessages([...messages, userMessage]); // Add user message to chat
      setInputValue(''); // Clear input after sending
      //       const system_prompt = `You are a customer service robot. ***At the end of message, you MUST output an MidJourney prompt starting with "/imaginStart" ending with "/imaginEnd" according to whole previous conversations***. Please ask the customer these questions to understand their T-shirt design needs:
      // What colour of T-shirt do you want to create?
      // Describe the image you want to generate.
      // Ask for more details about the image (e.g., style, elements, mood, specific actions/poses).
      // Don't exceed 7 questions.
      // start the conversation with the first question exactly, you can list a few answers for the question you just asked to remind the customer.
      // never mention "T-shirt" in the prompt, mention a design on (colour) background, and a transparent background for the graphic so that it blends well with the background
      // Include the T-shirt colour and add "no T-shirt" to ensure the T-shirt is not generated" While asking customer questions, please ask one at a time, wait for the response then ask the next one, also before the final prompt output, please correct the customer's input spelling mistake and try to interpret the intention of the customer, expand the abbreviation, or some words.
      // `;
      const system_prompt = `You are a customer service robot. you generate prompts for images  **While you finish asking all of the seven questions, you MUST output an a prompt starting with "/imaginStart" ending with "/imaginEnd" according to whole previous conversations**. if customer revise or giving more details, please change the prompt and display again.

Please ask the customer these questions to understand their T-shirt design needs:
What colour of T-shirt do you want to create?
Describe the image you want to generate.
Ask for more details about the image (e.g., style, elements, mood, specific actions/poses).
Don't exceed 7 questions.
start the conversation with the first question exactly, you can list a few answers for the question you just asked to remind the customer.
never mention "T-shirt" in the prompt, mention a design on (colour) background, and a transparent background for the graphic so that it blends well with the background
 Include the T-shirt colour and add "no T-shirt" to ensure the T-shirt is not generated" While asking customer questions, please ask one at a time, wait for the response then ask the next one, also before the final prompt output, please correct the customer's input spelling mistake and try to interpret the intention of the customer, expand the abbreviation, or some words.`
      // Call the chat API
      setMessages(prevMessages => [...prevMessages, { text: <DotLoading />, from: "left" }]); // Add loading message to chat
      chat({ prompt: inputValue, messages: completion, system_prompt }).then(response => {
        let startIndex = response.data.response.indexOf("/imaginStart");
        let endIndex = response.data.response.indexOf("/imaginEnd");
        let messageText = "";
        let promptText = "";

        if (startIndex !== -1 && endIndex !== -1) {
          startIndex += "/imaginStart".length;
          messageText = response.data.response.substring(0, startIndex).trim();
          promptText = response.data.response.substring(startIndex, endIndex).trim();
        } else {
          messageText = response.data.response.trim();
        }

        const aiMessage = { text: messageText, from: "left" };
        setMessages(prevMessages => [...prevMessages.slice(0, -1), aiMessage]);
        console.log(promptText);
        setPrompt(promptText); // Set the prompt
        setCompletion(response.data.completion);
      }).catch(error => {
        const errMessage = { text: "Failed to generate. Please try again.", from: "left" };
        setMessages(prevMessages => [...prevMessages.slice(0, -1), errMessage]);
      });
    }
  };
  // Function to handle key down event
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevent default behavior (new line)
      handleSend(); // Call send function
    }
  };

  const handleGenerate = () => {
    navigate(`/create-result/${prompt}`);
  }

  // Render the component
  return (
    <div className="create-from-description">
      <div className="head-area">
        <div className="title" onClick={() => navigate(`/creation-center`)}><FontAwesomeIcon icon={faArrowLeft} /> Create By Describing</div>
        <div className={`button ${prompt ? 'active' : ''}`} onClick={prompt ? handleGenerate : null}>
          Generate
        </div>
      </div>
      <div className="chat-area">
        {messages.map((msg, index) => (
          <div className={`chat-bubble ${msg.from}`} key={index}>
            {msg.from === "left" && <img src="../assets/images/gpt-icon.png" className="avatar" />}
            <div className="message">{msg.text}</div>
            {msg.from === "right" && <img src="https://placehold.co/24x24" className="avatar" />}
          </div>
        ))}
      </div>
      <div className="input-area">
        <div className="input">
          <FontAwesomeIcon icon={faPen} className='icon' />
          <textarea
            placeholder="Say something..."
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
            rows={3} // Set initial rows for the textarea
          />
        </div>
        <div className="button" onClick={handleSend}><FontAwesomeIcon icon={faPaperPlane} /></div>
      </div>
    </div>
  );
}

// Export the component
export default CreateFromDescription;