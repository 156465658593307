import { Button, Image, Swiper } from 'antd-mobile'; // Added Carousel
import { useNavigate } from 'react-router-dom';
import './index.css';

export default function Welcome() {
  const navigate = useNavigate();

  const jumpToLogin = () => {
    navigate('/login');
  }
  return (
    <div className='welcome-page'>
      <div className="container">
        <Swiper
          loop
          autoplay
          indicatorProps={{
            color: '#C622CF',
            style: {
              '--dot-color': 'rgba(255, 255, 255,0.5)',
              '--active-dot-color': '#C622CF',
              '--dot-size': '10px',
              '--active-dot-size': '10px',
              '--dot-border-radius': '100px',
              '--active-dot-border-radius': '100px',
              '--dot-spacing': '8px',
            }
          }}
        >
          <Swiper.Item>
            <Image width='100vw' height='100vh' src="../assets/images/welcome-1.png" fit='cover' />
          </Swiper.Item>
          <Swiper.Item>
            <Image width='100vw' height='100vh' src="../assets/images/welcome-2.png" fit='cover' />
          </Swiper.Item>
          <Swiper.Item>
            <Image width='100vw' height='100vh' src="../assets/images/welcome-3.png" fit='cover' />
          </Swiper.Item>
        </Swiper>

        <div className='main-content'>
          <div className="content">
            <img src="../assets/images/logo.png" alt="kurage logo" className="logo" />
          </div>
          <div className="button-container">
            <p className="subtitle">Realize Your Imagination!<br />Design and Sell T-shirts and products with AI</p>
            <button className="create-button" onClick={jumpToLogin}>Create Now</button>
          </div>
        </div>


      </div>
    </div>

  );
}