import { Button, Image, Swiper } from 'antd-mobile'; // Added Carousel
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BottomNav from '../../../components/BottomNav';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  faAngleDown
} from "@fortawesome/free-solid-svg-icons";
import './index.css';
import { getAllThemes } from '../../../api/theme';

export default function CreationCenter() {
  const [showContainer, setShowContainer] = useState(false);
  const tabs = ['All', 'Family', 'Friends', 'Free', 'More'];
  const [activeTab, setActiveTab] = useState('Family');
  const [gridItems, setGridItems] = useState([]);
  useEffect(() => {
    fetchThemes();
  }, []);

  const fetchThemes = () => {
    getAllThemes().then(res => {
      setGridItems(res.themes);
    });
  };

  const currentGridItems = gridItems.filter(item =>
    item.category === activeTab || activeTab === 'All'
  );
  const navigate = useNavigate();
  const itemsPerSwiper = 6; // 每个 Swiper.Item 中的项目数量
  const swiperItems = [];
  for (let i = 0; i < currentGridItems.length; i += itemsPerSwiper - 1) { // 修改步长为 itemsPerSwiper - 1
    const group = currentGridItems.slice(i, i + itemsPerSwiper - 1); // 只取 itemsPerSwiper - 1 项
    swiperItems.push([{ id: 'upload', imgSrc: '', alt: '', category: '' }, ...group]); // 每组都添加上传项
  }


  const handleTabClick = (tab) => {
    setActiveTab(tab); // 切换选项卡
  };


  return (
    <div className='creation-center-page'>

      <div className="footer">
        <div className="footer-item" onClick={() => setShowContainer(!showContainer)}>
          <div className='title'>Create by Themes<br /><span>Create designs by modifying a theme picture you like</span></div>
          <div className="bottom">
            <img src="../assets/images/creation-icon-0.png" className='left' />
            {showContainer ? <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 15L12 9L6 15" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg> : <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 18L15 12L9 6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>}
          </div>

        </div>
        {showContainer && (
          <div className="container">
            <div className="tabs">
              {tabs.map(tab => (
                <div
                  key={tab}
                  className={`tab ${activeTab === tab ? 'active' : ''}`}
                  onClick={() => handleTabClick(tab)}
                >
                  {tab} {tab === 'More' && <FontAwesomeIcon icon={faAngleDown} />}
                </div>
              ))}
            </div>

            <Swiper
              style={{
                '--border-radius': '8px',
              }}
              indicatorProps={{
                color: '#C622CF',
                style: {
                  '--dot-color': 'rgba(255, 255, 255,0.5)',
                  '--active-dot-color': '#C622CF',
                  '--dot-size': '10px',
                  '--active-dot-size': '10px',
                  '--dot-border-radius': '100px',
                  '--active-dot-border-radius': '100px',
                  '--dot-spacing': '8px',
                }
              }}
            >
              {swiperItems.map((items, index) => (
                <Swiper.Item key={index}>
                  <div className="grid">
                    {items.map((item) => (
                      item.id === 'upload' ? ( // 仅在第一个项目中显示上传项
                        <div className="grid-item upload" key={item._id} onClick={() => navigate(`/create-from-theme/upload`)}>
                          <div className="plus-icon">+</div>
                        </div>
                      ) : (
                        <div className="grid-item" key={item._id} onClick={() => navigate(`/create-from-theme/${item._id}`)}>
                          <img src={item.previewImage} alt={item.name} />
                          <div className="plus-icon">+</div>
                        </div>
                      )
                    ))}
                  </div>
                </Swiper.Item>
              ))}
            </Swiper>
          </div>
        )}


        <div className="footer-item" onClick={() => navigate(`/create-from-description`)}>
          <div className='title'>Create by Describing<br /><span>Create designs by telling AI what do you want to Create or write the prompt directly </span></div>
          <div className="bottom">
            <img src="../assets/images/creation-icon-2.png" className='left' />
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 18L15 12L9 6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        </div>




        <div className="footer-item">
          <div className='title'>Go to Product Maker directly<br /><span>You already have a good design and just want to launch the product with us</span></div>
          <div className="bottom">
            <img src="../assets/images/creation-icon-1.png" className='left' />
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 18L15 12L9 6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

          </div>
        </div>
      </div>
      <BottomNav />
    </div>
  );
}