import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Welcome from './views/Welcome';
import Login from './views/Login';
import Register from './views/Register';

import CreationCenter from './views/CreationCenter/index';
import CreateFromTheme from './views/CreationCenter/CreateFromTheme';
import CreateFromDescription from './views/CreationCenter/CreateFromDescription';
import CreateResult from './views/CreationCenter/CreateResult';
import CreateSelected from './views/CreationCenter/CreateSelected';

import Profile from './components/Profile';
import Search from './components/Search';
import Cart from './components/Cart';
import CreateArt from './components/CreateArt';

import './index.css';

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/creation-center" element={<CreationCenter />} />
          <Route path="/create-from-theme/:id" element={<CreateFromTheme />} />
          <Route path="/create-from-description" element={<CreateFromDescription />} />
          <Route path="/create-result/:prompt" element={<CreateResult />} />
          <Route path="/create-result-selected/:link" element={<CreateSelected />} />
          <Route path="/search" element={<Search />} />
          <Route path="/create" element={<CreateArt />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/profile" element={<Profile />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;